import React, { FunctionComponent, useEffect, useReducer, useState } from 'react';

import { useTranslation } from 'react-i18next';

import DataPaneTitleMenu from 'components/DataPane/DataPaneTitleMenu';
import DataPaneMenu from '../../DataPaneMenu';

import { EnergyStatisticsPane } from '../../../../styled/components/dataPane';

import StatisticsHeader from './StatisticsHeader';
import BillableConsumption from './BillableConsumption';
import ProductionConsumption from './ProductionConsumption';
import ElectricityConsumption from './ElectricityConsumption';
import GasConsumption from './GasConsumption';
import OccupancyConsumption from './OccupancyConsumption';
import TemperatureConsumption from './TemperatureConsumption';

import {
    createBillableGraphData,
    createTemperatureGraphData,
    createGasGraphData,
    createOccupancyGraphData,
    createProductionGraphData,
    createElectricityGraphData,
} from './energyStatistics.helper';
import { DateRange, createFromToDate, DateSpan } from 'utils/timePeriod';

import { DataPaneAction, DatapaneState } from './types';
import { Sensor, SensorAvgFilter, SensorAverage, ModuleType, SensorType, KpiUnit } from 'types';
import { BuildingConstants, MetaDataEwattch } from 'views/authenticated/energy/types';

import { useEnergyOccupancyData } from 'hooks/api/useEnergyOccupancyData';
import { useTemperatureData } from 'hooks/api/useTemperatureData';
import { useTop5Data } from 'hooks/api/useTop5Data';
import { useEnergyData } from 'hooks/api/useEnergyData';

import consumptionIcon from '../../../../assets/icons/i-m-energy.svg';
import gasIcon from '../../../../assets/icons/i-s-gas.svg';
import productionIcon from '../../../../assets/icons/i-s-luminocity.svg';
import moveIcon from '../../../../assets/icons/icons-sensor-type-s-move.svg';
import { GasContract, ElectricityContract } from 'views/authenticated/profile/types';

interface EnergyStatsDataPaneProps {
    buildingName: string;
    selectedRoom: string;
    buildingConstants: BuildingConstants;
    contracts: (ElectricityContract | GasContract)[];
    city: string;
    mainElectricitySensors: Sensor[];
    subElectrictySensors: Sensor[];
    ewattchSensors: MetaDataEwattch[];
    gasSensors: Sensor[];
    productionSensors: Sensor[];
    feedinSensors: Sensor[];
    occupancySensors: Sensor[];
    onExpand: (isExpanded: boolean) => void;
    onDateSelect: (action: string, timespan: DateSpan, customDate: DateRange) => void;
}

const EnergyStatsDataPane: FunctionComponent<EnergyStatsDataPaneProps> = ({
    buildingName,
    selectedRoom,
    buildingConstants,
    contracts,
    city,
    mainElectricitySensors,
    subElectrictySensors,
    ewattchSensors,
    gasSensors,
    productionSensors,
    feedinSensors,
    occupancySensors,
    onExpand,
    onDateSelect,
}) => {
    const { t } = useTranslation();

    const [isExpanded, setIsExpanded] = useState(false);
    const [state, dispatch] = useReducer(dataPaneReducer, initialState);
    const {
        action,
        timespan,
        customDate,
        mainElectricityParams,
        subMetersElectricityParams,
        ewattchElectricityParams,
        gasParams,
        productionParams,
        feedinParams,
        occupancyParams,
        temperatureParams,
        top5Meters,
        consumptionGraphData,
        electricityGraphData,
        gasGraphData,
        productionGraphData,
        temperatureGraphData,
        occupancyGraphData,
        isConsumptionBarChart,
        isProductionBarChart,
        isElectricityBarChart,
        isGasBarChart,
        isInGigajoules,
    } = state;

    const { data: temperatureData, isLoading: temperatureIsLoading } = useTemperatureData(temperatureParams);
    const { data: top5Data, isLoading: top5IsLoading } = useTop5Data(
        subMetersElectricityParams,
        ewattchElectricityParams,
        subElectrictySensors
    );
    const { data: energyData, isLoading: energyIsLoading } = useEnergyData(
        mainElectricityParams.ids.length ? mainElectricityParams : subMetersElectricityParams,
        ewattchElectricityParams,
        gasParams,
        productionParams,
        feedinParams,
        [...mainElectricitySensors, ...subElectrictySensors],
        productionSensors,
        feedinSensors
    );
    const { data: occupancyData, isLoading: occupancyIsLoading } = useEnergyOccupancyData(
        occupancyParams,
        selectedRoom,
        occupancySensors
    );

    useEffect(() => {
        if (energyData) {
            dispatch({
                type: 'SET_TOP5_GRAPH_DATA',
                electricityUsage: energyData.actualElectricityUsage,
                top5: top5Data || [],
            });
        }
    }, [top5Data, energyData]);

    useEffect(() => {
        if (energyData) {
            const sensorType = subElectrictySensors.some(s => s.type === SensorType.HEATING)
                ? SensorType.HEATING
                : subElectrictySensors.some(s => s.type === SensorType.COOLING)
                ? SensorType.COOLING
                : SensorType.ELECTRICITY;
            const isInGigajoules = subElectrictySensors.some(s => s.unit === KpiUnit.GJ);
            dispatch({
                type: 'SET_GRAPH_DATA',
                energyData,
                contracts,
                sensorType,
                isInGigajoules,
            });
        }
    }, [energyData]);

    useEffect(() => {
        if (energyData && occupancyData) {
            dispatch({
                type: 'SET_OCCUPANCY_GRAPH_DATA',
                actualUsage: energyData.actualElectricityUsage,
                gasData: energyData.gas,
                occupancyData: occupancyData || [],
            });
        }
    }, [energyData, occupancyData]);

    useEffect(() => {
        if (energyData && temperatureData) {
            dispatch({
                type: 'SET_TEMPERATURE_GRAPH_DATA',
                actualUsage: energyData.actualElectricityUsage,
                gasData: energyData.gas,
                temperatureData,
            });
        }
    }, [energyData, temperatureData]);

    useEffect(() => {
        dispatch({
            type: 'CHANGE_SENSOR_SELECTION',
            building: buildingName,
            room: selectedRoom,
            city: city,
            gasConsumptionSensors: gasSensors,
            mainElectricityConsumptionSensors: mainElectricitySensors,
            subMeterElectricityConsumptionSensors: subElectrictySensors,
            ewattchSensors: ewattchSensors,
            productionSensors: productionSensors.filter(s => s.type === SensorType.ELECTRICITY && s.isMain),
            feedinSensors: feedinSensors,
            occupancySensors: occupancySensors,
        });
    }, [buildingName, selectedRoom]);

    const expand = (expand: boolean) => {
        setIsExpanded(expand);
        onExpand(expand);
    };

    const onSelectAction = (action: string, timespan: DateSpan, customDate: DateRange) => {
        dispatch({
            type: 'CHANGE_DATE_SPAN',
            action,
            timespan,
            customDate: customDate || { from: '', to: '' },
        });
        onDateSelect(action, timespan, customDate);
    };

    return (
        <EnergyStatisticsPane isExpanded={isExpanded}>
            <DataPaneMenu
                customDate={customDate}
                title={buildingName}
                selectedAction={action}
                onSelectAction={onSelectAction}
                module={ModuleType.ENERGY}
                canExpand={true}
                expand={isExpanded}
                onExpand={expand}
            />

            <StatisticsHeader
                isLoading={energyIsLoading}
                isLoadingTop5={top5IsLoading}
                top5Meters={top5Meters}
                gasSensors={gasSensors}
                mainElectricitySensors={mainElectricitySensors}
                subElectricitySensors={subElectrictySensors}
                billableGraph={consumptionGraphData}
                productionGraph={productionGraphData}
                constants={buildingConstants}
            />

            {isExpanded && !isInGigajoules && (
                <>
                    <DataPaneTitleMenu
                        title={t('energy.datapane.consumptionTitle')}
                        icon={consumptionIcon}
                        canToggleGraph={true}
                        isBarChart={isConsumptionBarChart}
                        onToggleGraph={isBarChart => dispatch({ type: 'TOGGLE_CONSUMPTION_CHART', isBarChart })}
                    />

                    <BillableConsumption
                        graphData={consumptionGraphData}
                        sensors={[...mainElectricitySensors, ...gasSensors]}
                        ewattchSensors={ewattchSensors}
                        isLoadingConsumption={energyIsLoading}
                        isBarChart={isConsumptionBarChart}
                    />
                </>
            )}
            {isExpanded &&
                (hasElectricitySensors([...mainElectricitySensors, ...subElectrictySensors]) ||
                    ewattchSensors.length) && (
                    <>
                        <DataPaneTitleMenu
                            title={t('energy.datapane.electricityTitle', {
                                period: t(`common.time.${timespan === DateSpan.YEAR ? 'day' : 'hour'}`).toLowerCase(),
                            })}
                            icon={consumptionIcon}
                            canToggleGraph={true}
                            isBarChart={isElectricityBarChart}
                            onToggleGraph={isBarChart => dispatch({ type: 'TOGGLE_ELECTRICITY_CHART', isBarChart })}
                        />
                        <ElectricityConsumption
                            graphData={electricityGraphData}
                            consumptionGraphData={consumptionGraphData}
                            sensors={[...mainElectricitySensors, ...subElectrictySensors]}
                            isLoadingConsumption={energyIsLoading}
                            isBarChart={isElectricityBarChart}
                            buildingConstants={buildingConstants}
                        />
                    </>
                )}
            {isExpanded && Boolean(productionSensors.length) && (
                <>
                    <DataPaneTitleMenu
                        title={t('energy.datapane.productionTitle')}
                        icon={productionIcon}
                        canToggleGraph={true}
                        isBarChart={isProductionBarChart}
                        onToggleGraph={isBarChart => dispatch({ type: 'TOGGLE_PRODUCTION_CHART', isBarChart })}
                    />
                    <ProductionConsumption
                        graphData={productionGraphData}
                        sensors={productionSensors.filter(s => s.type === SensorType.ELECTRICITY && s.isMain)}
                        isLoadingConsumption={energyIsLoading}
                        isBarChart={isProductionBarChart}
                    />
                </>
            )}

            {isExpanded && hasGasSensors(gasSensors) && (
                <>
                    <DataPaneTitleMenu
                        title={t('energy.datapane.gasTitle')}
                        icon={gasIcon}
                        canToggleGraph={true}
                        isBarChart={isGasBarChart}
                        onToggleGraph={isBarChart => dispatch({ type: 'TOGGLE_GAS_CHART', isBarChart })}
                    />
                    <GasConsumption
                        graphData={gasGraphData}
                        sensors={gasSensors}
                        isLoadingConsumption={energyIsLoading}
                        isBarChart={isGasBarChart}
                    />
                </>
            )}

            {isExpanded && (
                <>
                    <DataPaneTitleMenu title={t('energy.datapane.consumptionTemperatureTitle')} icon={productionIcon} />
                    <TemperatureConsumption
                        graphData={temperatureGraphData}
                        sensors={[...mainElectricitySensors, ...gasSensors]}
                        isLoadingConsumption={energyIsLoading}
                        isLoadingTemperature={temperatureIsLoading}
                    />
                </>
            )}
            {isExpanded && Boolean(occupancySensors.length) && (
                <>
                    <DataPaneTitleMenu
                        title={t('energy.datapane.occupancyTitle')}
                        icon={moveIcon}
                        canToggleGraph={false}
                    />
                    <OccupancyConsumption
                        graphData={occupancyGraphData}
                        sensors={[...mainElectricitySensors, ...gasSensors]}
                        isLoadingConsumption={energyIsLoading}
                        isLoadingOccupancy={occupancyIsLoading}
                    />
                </>
            )}
        </EnergyStatisticsPane>
    );
};

export default EnergyStatsDataPane;

const initialState: DatapaneState = {
    mainElectricityParams: {
        averageFilter: SensorAvgFilter.HOUR_OF_DAY,
        from: '',
        to: '',
        ids: [],
        building: '',
        room: '',
    },
    subMetersElectricityParams: {
        averageFilter: SensorAvgFilter.HOUR_OF_DAY,
        from: '',
        to: '',
        ids: [],
        building: '',
        room: '',
    },
    ewattchElectricityParams: {
        averageFilter: SensorAvgFilter.HOUR_OF_DAY,
        from: '',
        to: '',
        ids: [],
        building: '',
        room: '',
    },
    gasParams: {
        averageFilter: SensorAvgFilter.HOUR_OF_DAY,
        from: '',
        to: '',
        ids: [],
        building: '',
        room: '',
    },
    productionParams: {
        averageFilter: SensorAvgFilter.HOUR_OF_DAY,
        from: '',
        to: '',
        ids: [],
        building: '',
        room: '',
    },
    feedinParams: {
        averageFilter: SensorAvgFilter.HOUR_OF_DAY,
        from: '',
        to: '',
        ids: [],
        building: '',
        room: '',
    },
    occupancyParams: {
        from: '',
        to: '',
        building: '',
    },
    temperatureParams: {
        from: '',
        to: '',
        city: '',
        averageFilter: null,
    },
    city: '',
    top5Meters: [],
    consumptionGraphData: null,
    gasGraphData: null,
    productionGraphData: null,
    temperatureGraphData: null,
    occupancyGraphData: null,
    electricityGraphData: null,
    action: DateSpan.TWO_DAYS,
    timespan: DateSpan.TWO_DAYS,
    isConsumptionBarChart: false,
    isProductionBarChart: false,
    isGasBarChart: false,
    isElectricityBarChart: false,
    isInGigajoules: false,
    customDate: {
        from: '',
        to: '',
    },
};

const dataPaneReducer = (state: DatapaneState, action: DataPaneAction): DatapaneState => {
    switch (action.type) {
        case 'SET_GRAPH_DATA': {
            const consumptionGraphData = createBillableGraphData(
                action.energyData.electricity,
                action.energyData.gas,
                state.timespan,
                state.action,
                state.customDate,
                action.contracts,
                state.mainElectricityParams.building,
                action.sensorType,
                action.isInGigajoules
            );
            const gasGraphData = createGasGraphData(
                action.energyData.gas,
                action.energyData.gasHistoric,
                state.timespan,
                state.action,
                state.customDate
            );
            const productionGraphData = createProductionGraphData(
                action.energyData.electricity,
                action.energyData.production,
                action.energyData.feedin,
                state.timespan,
                state.action,
                state.customDate
            );
            return {
                ...state,
                consumptionGraphData,
                gasGraphData,
                productionGraphData,
                isInGigajoules: action.isInGigajoules,
            };
        }
        case 'SET_OCCUPANCY_GRAPH_DATA': {
            const occupancyData = action.occupancyData.map(d => ({
                value: d.occupied,
                field: d.date,
            })) as SensorAverage[];
            const occupancyGraphData = createOccupancyGraphData(
                action.actualUsage,
                action.gasData,
                occupancyData,
                state.timespan,
                state.action,
                state.customDate
            );

            return {
                ...state,
                occupancyGraphData,
            };
        }
        case 'SET_TEMPERATURE_GRAPH_DATA': {
            const temperatureGraphData = createTemperatureGraphData(
                action.actualUsage,
                action.gasData,
                action.temperatureData,
                state.timespan,
                state.action,
                state.customDate,
                state.isInGigajoules
            );

            return {
                ...state,
                temperatureGraphData,
            };
        }
        case 'SET_TOP5_GRAPH_DATA': {
            const electricityGraphData = createElectricityGraphData(
                action.top5,
                action.electricityUsage,
                state.timespan,
                state.action,
                state.customDate
            );

            return {
                ...state,
                electricityGraphData,
                top5Meters: action.top5,
            };
        }
        case 'CHANGE_DATE_SPAN': {
            const { from, to } =
                action.action === DateSpan.CUSTOM ? action.customDate : createFromToDate(action.timespan);
            const customDate = action.action === DateSpan.CUSTOM ? action.customDate : state.customDate;
            const avgfilter = action.timespan === DateSpan.YEAR ? SensorAvgFilter.DAY : SensorAvgFilter.HOUR_OF_DAY;

            return {
                ...state,
                action: action.action,
                timespan: action.timespan,
                customDate,
                consumptionGraphData: null,
                productionGraphData: null,
                gasGraphData: null,
                electricityGraphData: null,
                mainElectricityParams: { ...state.mainElectricityParams, averageFilter: avgfilter, from, to },
                subMetersElectricityParams: { ...state.subMetersElectricityParams, averageFilter: avgfilter, from, to },
                ewattchElectricityParams: { ...state.ewattchElectricityParams, averageFilter: avgfilter, from, to },
                gasParams: { ...state.gasParams, averageFilter: avgfilter, from, to },
                productionParams: {
                    ...state.productionParams,
                    averageFilter: avgfilter,
                    from,
                    to,
                },
                feedinParams: {
                    ...state.feedinParams,
                    averageFilter: avgfilter,
                    from,
                    to,
                },
                occupancyParams: {
                    ...state.occupancyParams,
                    averageFilter: action.timespan === DateSpan.YEAR ? SensorAvgFilter.DAY : null,
                    from,
                    to,
                },
                temperatureParams: {
                    ...state.temperatureParams,
                    averageFilter: action.timespan === DateSpan.YEAR ? SensorAvgFilter.DAY : null,
                    from,
                    to,
                },
            };
        }
        case 'CHANGE_SENSOR_SELECTION': {
            const { from, to } = state.action === DateSpan.CUSTOM ? state.customDate : createFromToDate(state.timespan);
            const avgFilter = state.timespan === DateSpan.YEAR ? SensorAvgFilter.DAY : SensorAvgFilter.HOUR_OF_DAY;

            return {
                ...state,
                city: action.city,
                consumptionGraphData: null,
                productionGraphData: null,
                gasGraphData: null,
                electricityGraphData: null,
                mainElectricityParams: {
                    averageFilter: avgFilter,
                    from,
                    to,
                    ids: action.mainElectricityConsumptionSensors.map(sensor => sensor.id),
                    building: action.building,
                    room: action.room,
                },
                subMetersElectricityParams: {
                    averageFilter: avgFilter,
                    from,
                    to,
                    ids: action.subMeterElectricityConsumptionSensors.map(sensor => sensor.id),
                    building: action.building,
                    room: action.room,
                },
                ewattchElectricityParams: {
                    averageFilter: avgFilter,
                    from,
                    to,
                    ids: action.ewattchSensors.map(sensor => sensor.equipId),
                    building: action.building,
                    room: action.room,
                },
                gasParams: {
                    averageFilter: avgFilter,
                    from,
                    to,
                    ids: action.gasConsumptionSensors.map(sensor => sensor.id),
                    building: action.building,
                    room: action.room,
                },
                productionParams: {
                    averageFilter: avgFilter,
                    from,
                    to,
                    ids: action.productionSensors.map(sensor => sensor.id),
                    building: action.building,
                    room: action.room,
                },
                feedinParams: {
                    averageFilter: avgFilter,
                    from,
                    to,
                    ids: action.feedinSensors.map(sensor => sensor.id),
                    building: action.building,
                    room: action.room,
                },
                occupancyParams: {
                    from,
                    to,
                    averageFilter: state.timespan === DateSpan.YEAR ? SensorAvgFilter.DAY : null,
                    building: action.occupancySensors.length ? action.occupancySensors[0].buildingRef : '',
                },
                temperatureParams: {
                    city: action.city,
                    averageFilter: state.timespan === DateSpan.YEAR ? SensorAvgFilter.DAY : null,
                    from,
                    to,
                },
            };
        }
        case 'TOGGLE_CONSUMPTION_CHART':
            return {
                ...state,
                isConsumptionBarChart: action.isBarChart,
            };
        case 'TOGGLE_PRODUCTION_CHART':
            return {
                ...state,
                isProductionBarChart: action.isBarChart,
            };
        case 'TOGGLE_GAS_CHART':
            return {
                ...state,
                isGasBarChart: action.isBarChart,
            };
        case 'TOGGLE_ELECTRICITY_CHART':
            return {
                ...state,
                isElectricityBarChart: action.isBarChart,
            };
    }
};

const hasGasSensors = (sensors: Sensor[]): boolean => {
    return Boolean(sensors.filter(s => s.type === SensorType.GAS).length);
};

const hasElectricitySensors = (sensors: Sensor[]): boolean => {
    return Boolean(sensors.filter(s => s.type === SensorType.ELECTRICITY).length);
};
