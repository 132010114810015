import { numberFormatter } from 'components/DataPane/statistics/energy/energyStatistics.helper';
import React, { createRef, useEffect, useRef, useCallback } from 'react';

import { SensorListContainer, SensorList, SensorListItem, SensorWrapper } from 'styled/components/sensors';
import { KpiUnit } from 'types';
import { MeterList } from '../../views/authenticated/types';

type DispatchType = 'SELECT_SPACE1' | 'SELECT_SPACE2' | 'SELECT_SPACE3' | 'SELECT_SPACE4' | 'SELECT_SPACE5';
const dispatchType = ['SELECT_SPACE1', 'SELECT_SPACE2', 'SELECT_SPACE3', 'SELECT_SPACE4', 'SELECT_SPACE5'] as const;

interface MeterSensorSelectorProps {
    meters: MeterList[];
    onEquipSelect: (selectAction: DispatchType, roomId: string) => void;
    collapsed: boolean;
}
const MeterSensorSelector: React.FunctionComponent<MeterSensorSelectorProps> = ({
    meters,
    onEquipSelect,
    collapsed,
}) => {
    const elementsRef = useRef<Array<Array<React.RefObject<HTMLLIElement>>>>(
        meters.map(meter => meter.meters.map(() => createRef<HTMLLIElement>()))
    );
    const sensorWrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (meters.length > 0 && elementsRef.current.length) {
            scrollSelectedItemInView();
        }
    }, [meters]);

    const scrollSelectedItemInView = useCallback(() => {
        const selectedSpaces: Array<number> = [];
        meters.map((meters, meterIndex) => {
            meters.meters.map((meter, index) => {
                if (meters.selectedMeter === meter.pointId) {
                    selectedSpaces[meterIndex] = index;
                }
            });
        });
        selectedSpaces.forEach((value, index) => {
            const boundingRectWrapper = sensorWrapperRef.current?.getBoundingClientRect();
            const boundingRect = elementsRef.current[index][value]?.current?.getBoundingClientRect();
            if (
                (boundingRect && boundingRectWrapper && boundingRect.bottom > boundingRectWrapper.bottom) ||
                (boundingRect && boundingRectWrapper && boundingRect.top < boundingRectWrapper.top)
            ) {
                elementsRef.current[index][value]?.current?.scrollIntoView();
            }
        });
    }, [meters]);

    return (
        <SensorWrapper ref={sensorWrapperRef} collapsed={collapsed}>
            {meters.map((submeters, meterIndex) => {
                return (
                    <SensorListContainer key={meterIndex}>
                        <SensorList>
                            {submeters.meters.map((submeter, index) => (
                                <SensorListItem
                                    ref={elementsRef.current[meterIndex][index]}
                                    hasIssue={false} //meter.hasError
                                    selected={submeters.selectedMeter === submeter.pointId}
                                    onClick={() => {
                                        // Disable click on same button
                                        submeters.selectedMeter === submeter.pointId &&
                                        !meters[meterIndex + 1].selectedMeter
                                            ? null
                                            : onEquipSelect(dispatchType[meterIndex], submeter.pointId);
                                    }}
                                    key={submeter.pointId}
                                >
                                    <div>
                                        <p>{meterIndex === 0 ? submeter.siteName : submeter.name}</p>
                                        <p />
                                        {Boolean(submeter.value || submeter.value === 0) && (
                                            <p>{accumulatedSpaceValue(submeter.value)}</p>
                                        )}
                                    </div>
                                </SensorListItem>
                            ))}
                        </SensorList>
                    </SensorListContainer>
                );
            })}
        </SensorWrapper>
    );
};

export default MeterSensorSelector;

const accumulatedSpaceValue = (value: number) => {
    return typeof value === 'number'
        ? ` ${numberFormatter(value, 2)} ${value > 999 ? KpiUnit.MWH : KpiUnit.KWH}`
        : null;
};
