import { EquipList, Space } from '../types';
import { SpaceSuggestion } from 'components/SearchAutoComplete';

export type FeedbackPayload = {
    client_id: string;
    client_secret: string;
    type: SupportType;
    header: string;
    message: string;
};

export enum SupportType {
    QUESTION = 'Question',
    MODULEACTIVATION = 'ModuleActivation',
    BUG = 'Bug',
    DEMOREQUEST = 'DemoRequest',
}

export type PasswordPayload = {
    client_id: string;
    client_secret: string;
    email: string;
    oldPassword: string;
    newPassword: string;
    newPassword2: string;
};

export type NamePayload = {
    name: string;
};

export interface ManagementViewState {
    buildingStructure: Space[];
    selectedSpaceId: string;
    selectedSpace: Space;
    equipOverview: EquipList[];
    suggestions: SpaceSuggestion[];
}

export type ManagementViewAction =
    | { type: 'SELECT_SPACE1'; spaceId: string }
    | { type: 'SELECT_SPACE2'; spaceId: string }
    | { type: 'SELECT_SPACE3'; spaceId: string }
    | { type: 'SELECT_SPACE4'; spaceId: string }
    | { type: 'SELECT_SPACE5'; spaceId: string }
    | {
          type: 'INIT_SENSORS';
          buildingData: Space[];
      }
    | { type: 'SEARCH_SENSOR'; selectedSpace: SpaceSuggestion };

export interface ContractPreference {
    energy_contract: (ElectricityContract | GasContract)[];
}

export interface Contract {
    id: string;
    building: string;
    energyType: EnergyType;
    contractType: ContractType;
    startDate: string;
    endDate: string;
    serviceCost: number;
    contractCost: number;
}

export interface ElectricityContract extends Contract {
    lowPrice: number;
    highPrice: number;
    startPeak?: number;
    endPeak?: number;
}

export const isElectricityContract = (contract: Contract): contract is ElectricityContract => {
    return (<ElectricityContract>contract).lowPrice !== undefined &&
        (<ElectricityContract>contract).highPrice !== undefined &&
        (<ElectricityContract>contract)?.startPeak !== undefined &&
        (<ElectricityContract>contract)?.endPeak !== undefined
        ? true
        : (<ElectricityContract>contract).lowPrice !== undefined &&
          (<ElectricityContract>contract).highPrice !== undefined
        ? true
        : false;
};

export interface GasContract extends Contract {
    price: number;
}

export const isGasContract = (contract: Contract): contract is GasContract => {
    return (<GasContract>contract).price !== undefined;
};

export interface ContractForm {
    id: string;
    building: string;
    energyType: EnergyType;
    contractType: ContractType;
    startDate: string;
    endDate: string;
    serviceCost: string;
    contractCost: string;
    lowPrice: string;
    highPrice: string;
    startPeak?: string;
    endPeak?: string;
    price: string;
}

export enum EnergyType {
    ELECTRICITY = 'electricity',
    GAS = 'gas',
}

export enum ContractType {
    CONSTANT = 'constant',
    PEAK = 'peak',
    DYNAMIC = 'dynamic',
}

export enum ModalType {
    NONE = 'none',
    DELETE = 'delete',
    CREATE = 'create',
    VIEW = 'view',
}
