import React, { useEffect, useReducer } from 'react';

import ModulesSubMenu, { navItemsMobility } from 'components/Submenu/ModulesSubMenu';
import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';
import SensorSelector from 'components/SensorSelector';
import { createSensorSelection, getSelectedSpace } from '../sensors/SensorsOverviewView';
import { SpaceSuggestion } from 'components/SearchAutoComplete';

import { useTenant } from 'context/TenantContext';
import { useSensorsByModulesData } from 'hooks/api/useSensorsByModulesData';

import { ModuleType, Sensor } from 'types';
import { SensorSelection, Space } from '../types';
import { MobilityStatisticState, MobilityStatisticAction } from './types';

const MobilityStatisticsView: React.FunctionComponent = () => {
    const { getBuildingData } = useTenant();
    const { data, isLoading } = useSensorsByModulesData([ModuleType.MOBILITY]);
    const [state, dispatch] = useReducer(MobilityStatisticsReducer, initialState);
    const { equipOverview, selectedBuildingId, selectedRoomId } = state;

    useEffect(() => {
        if (data) {
            dispatch({
                type: 'INIT_SENSORS',
                buildingData: getBuildingData(),
                sensorData: data[0],
            });
        }
    }, [data]);

    return (
        <>
            <ModulesSubMenu
                subNavItems={navItemsMobility}
                suggestions={[]}
                onSearch={suggestions => console.log(suggestions)}
            />
            {isLoading && <LoadingOverlay />}

            {Boolean(equipOverview.length) && (
                <SensorSelector
                    equips={equipOverview}
                    onEquipSelect={(selectAction, roomId) => dispatch({ type: selectAction, spaceId: roomId })}
                    collapsed={Boolean(selectedBuildingId) || Boolean(selectedRoomId)}
                />
            )}
        </>
    );
};

export default MobilityStatisticsView;

export const initialState: MobilityStatisticState = {
    buildingStructure: [],
    suggestions: [],
    sensors: [],
    equipOverview: [],
    selectedBuildingId: '',
    selectedRoomId: '',
    breadCrumbs: [],
};

const emptyList = { spaces: [] as Space[], selectedSpace: '' };

const MobilityStatisticsReducer = (
    state: MobilityStatisticState,
    action: MobilityStatisticAction
): MobilityStatisticState => {
    switch (action.type) {
        case 'INIT_SENSORS': {
            const sensorSelection = localStorage.getItem('occupancy-stats-selection');
            const parsedSelection: SensorSelection | null = sensorSelection ? JSON.parse(sensorSelection) : null;

            const filteredBuildingData = action.buildingData.filter(building =>
                Boolean(action.sensorData.filter(f => f.buildingRef === building.id).length)
            );

            filteredBuildingData.forEach(building => {
                recursiveModuleSensorCheck(building.rooms, action.sensorData);
            });

            filteredBuildingData.forEach(building => {
                recursiveFilterRooms(building);
            });

            const suggestions: SpaceSuggestion[] = [];
            filteredBuildingData.forEach(building => {
                const location = [building.name];
                const ids = [building.id];
                const suggestion: SpaceSuggestion = {
                    name: building.name,
                    ids,
                    location,
                };
                suggestions.push(suggestion);

                recursiveCreateSuggestions(building.rooms, suggestions, ids, location);
            });

            const selectedBuilding = filteredBuildingData.find(i => i.id === parsedSelection?.selectedId1);
            const selectedSecondLevelRoom = selectedBuilding?.rooms.find(i => i.id === parsedSelection?.selectedId2);
            const selectedThirdLevelRoom = selectedSecondLevelRoom?.rooms.find(
                i => i.id === parsedSelection?.selectedId3
            );
            const selectedFourthLevelRoom = selectedThirdLevelRoom?.rooms.find(
                i => i.id === parsedSelection?.selectedId4
            );
            const selectedFifthLevelRoom = selectedFourthLevelRoom?.rooms.find(
                i => i.id === parsedSelection?.selectedId5
            );

            const breadCrumbs = [
                selectedBuilding?.name,
                selectedSecondLevelRoom?.name,
                selectedThirdLevelRoom?.name,
                selectedFourthLevelRoom?.name,
                selectedFifthLevelRoom?.name,
            ].filter((i): i is string => {
                return typeof i === 'string';
            });

            return {
                ...state,
                suggestions: suggestions,
                sensors: action.sensorData,
                buildingStructure: filteredBuildingData,
                equipOverview: [
                    {
                        spaces: filteredBuildingData || [],
                        selectedSpace: parsedSelection?.selectedId1 ? parsedSelection.selectedId1 : '',
                    },
                    {
                        spaces: selectedBuilding?.rooms || [],
                        selectedSpace: parsedSelection?.selectedId2 ? parsedSelection.selectedId2 : '',
                    },
                    {
                        spaces: selectedSecondLevelRoom?.rooms || [],
                        selectedSpace: parsedSelection?.selectedId3 ? parsedSelection.selectedId3 : '',
                    },
                    {
                        spaces: selectedThirdLevelRoom?.rooms || [],
                        selectedSpace: parsedSelection?.selectedId4 ? parsedSelection.selectedId4 : '',
                    },
                    {
                        spaces: selectedFourthLevelRoom?.rooms || [],
                        selectedSpace: parsedSelection?.selectedId5 ? parsedSelection.selectedId5 : '',
                    },
                ],
                selectedBuildingId: parsedSelection?.selectedId1 ? parsedSelection.selectedId1 : '',
                selectedRoomId: parsedSelection?.selectedId5
                    ? parsedSelection.selectedId5
                    : parsedSelection?.selectedId4
                    ? parsedSelection.selectedId4
                    : parsedSelection?.selectedId3
                    ? parsedSelection.selectedId3
                    : parsedSelection?.selectedId2
                    ? parsedSelection.selectedId2
                    : '',
                breadCrumbs,
            };
        }
        case 'SELECT_SPACE1': {
            localStorage.setItem(
                'occupancy-stats-selection',
                JSON.stringify({
                    selectedId1: action.spaceId,
                })
            );

            const breadCrumbs = [state.equipOverview[0].spaces.find(i => i.id === action.spaceId)?.name].filter(
                (i): i is string => {
                    return typeof i === 'string';
                }
            );

            return {
                ...state,
                equipOverview: [
                    {
                        spaces: state.equipOverview[0].spaces,
                        selectedSpace: action.spaceId,
                    },
                    {
                        spaces: state.equipOverview[0].spaces.find(i => i.id === action.spaceId)?.rooms || [],
                        selectedSpace: '',
                    },
                    emptyList,
                    emptyList,
                    emptyList,
                ],
                selectedBuildingId: action.spaceId,
                selectedRoomId: '',
                breadCrumbs,
            };
        }
        case 'SELECT_SPACE2': {
            localStorage.setItem(
                'occupancy-stats-selection',
                JSON.stringify({
                    selectedId1: state.equipOverview[0].selectedSpace,
                    selectedId2: action.spaceId,
                })
            );

            const breadCrumbs = [
                state.equipOverview[0].spaces.find(i => i.id === state.equipOverview[0].selectedSpace)?.name,
                state.equipOverview[1].spaces.find(i => i.id === action.spaceId)?.name,
            ].filter((i): i is string => {
                return typeof i === 'string';
            });

            return {
                ...state,
                equipOverview: [
                    state.equipOverview[0],
                    {
                        spaces: state.equipOverview[1].spaces,
                        selectedSpace: action.spaceId,
                    },
                    {
                        spaces: state.equipOverview[1].spaces.find(i => i.id === action.spaceId)?.rooms || [],
                        selectedSpace: '',
                    },
                    emptyList,
                    emptyList,
                ],
                selectedRoomId: action.spaceId,
                breadCrumbs,
            };
        }
        case 'SELECT_SPACE3': {
            localStorage.setItem(
                'occupancy-stats-selection',
                JSON.stringify({
                    selectedId1: state.equipOverview[0].selectedSpace,
                    selectedId2: state.equipOverview[1].selectedSpace,
                    selectedId3: action.spaceId,
                })
            );

            const breadCrumbs = [
                state.equipOverview[0].spaces.find(i => i.id === state.equipOverview[0].selectedSpace)?.name,
                state.equipOverview[1].spaces.find(i => i.id === state.equipOverview[1].selectedSpace)?.name,
                state.equipOverview[2].spaces.find(i => i.id === action.spaceId)?.name,
            ].filter((i): i is string => {
                return typeof i === 'string';
            });

            return {
                ...state,
                equipOverview: [
                    state.equipOverview[0],
                    state.equipOverview[1],
                    {
                        spaces: state.equipOverview[2].spaces,
                        selectedSpace: action.spaceId,
                    },
                    {
                        spaces: state.equipOverview[2].spaces.find(i => i.id === action.spaceId)?.rooms || [],
                        selectedSpace: '',
                    },
                    emptyList,
                ],
                selectedRoomId: action.spaceId,
                breadCrumbs,
            };
        }
        case 'SELECT_SPACE4': {
            localStorage.setItem(
                'occupancy-stats-selection',
                JSON.stringify({
                    selectedId1: state.equipOverview[0].selectedSpace,
                    selectedId2: state.equipOverview[1].selectedSpace,
                    selectedId3: state.equipOverview[2].selectedSpace,
                    selectedId4: action.spaceId,
                })
            );

            const breadCrumbs = [
                state.equipOverview[0].spaces.find(i => i.id === state.equipOverview[0].selectedSpace)?.name,
                state.equipOverview[1].spaces.find(i => i.id === state.equipOverview[1].selectedSpace)?.name,
                state.equipOverview[2].spaces.find(i => i.id === state.equipOverview[1].selectedSpace)?.name,
                state.equipOverview[3].spaces.find(i => i.id === action.spaceId)?.name,
            ].filter((i): i is string => {
                return typeof i === 'string';
            });

            return {
                ...state,
                equipOverview: [
                    state.equipOverview[0],
                    state.equipOverview[1],
                    state.equipOverview[2],
                    {
                        spaces: state.equipOverview[3].spaces,
                        selectedSpace: action.spaceId,
                    },
                    {
                        spaces: state.equipOverview[3].spaces.find(i => i.id === action.spaceId)?.rooms || [],
                        selectedSpace: '',
                    },
                ],
                selectedRoomId: action.spaceId,
                breadCrumbs,
            };
        }
        case 'SELECT_SPACE5': {
            localStorage.setItem(
                'occupancy-stats-selection',
                JSON.stringify({
                    selectedId1: state.equipOverview[0].selectedSpace,
                    selectedId2: state.equipOverview[1].selectedSpace,
                    selectedId3: state.equipOverview[2].selectedSpace,
                    selectedId4: state.equipOverview[3].selectedSpace,
                    selectedId5: action.spaceId,
                })
            );

            const breadCrumbs = [
                state.equipOverview[0].spaces.find(i => i.id === state.equipOverview[0].selectedSpace)?.name,
                state.equipOverview[1].spaces.find(i => i.id === state.equipOverview[1].selectedSpace)?.name,
                state.equipOverview[2].spaces.find(i => i.id === state.equipOverview[1].selectedSpace)?.name,
                state.equipOverview[3].spaces.find(i => i.id === state.equipOverview[3].selectedSpace)?.name,
                state.equipOverview[4].spaces.find(i => i.id === action.spaceId)?.name,
            ].filter((i): i is string => {
                return typeof i === 'string';
            });

            return {
                ...state,
                equipOverview: [
                    state.equipOverview[0],
                    state.equipOverview[1],
                    state.equipOverview[2],
                    state.equipOverview[3],
                    {
                        spaces: state.equipOverview[4].spaces,
                        selectedSpace: action.spaceId,
                    },
                ],
                selectedRoomId: action.spaceId,
                breadCrumbs,
            };
        }
        case 'SEARCH_SENSOR': {
            const sensorSelection = createSensorSelection(action.selectedSpace.ids);
            const selectedSpace = getSelectedSpace(sensorSelection);
            localStorage.setItem('occupancy-stats-selection', JSON.stringify(sensorSelection));

            const selectedBuilding = state.buildingStructure.find(i => i.id === sensorSelection?.selectedId1);
            const selectedSecondLevelRoom = selectedBuilding?.rooms.find(i => i.id === sensorSelection?.selectedId2);
            const selectedThirdLevelRoom = selectedSecondLevelRoom?.rooms.find(
                i => i.id === sensorSelection?.selectedId3
            );
            const selectedFourthLevelRoom = selectedThirdLevelRoom?.rooms.find(
                i => i.id === sensorSelection?.selectedId4
            );
            const selectedFifthLevelRoom = selectedFourthLevelRoom?.rooms.find(
                i => i.id === sensorSelection?.selectedId5
            );

            const breadCrumbs = [
                selectedBuilding?.name,
                selectedSecondLevelRoom?.name,
                selectedThirdLevelRoom?.name,
                selectedFourthLevelRoom?.name,
                selectedFifthLevelRoom?.name,
            ].filter((i): i is string => {
                return typeof i === 'string';
            });

            return {
                ...state,
                selectedBuildingId: sensorSelection?.selectedId1 ? sensorSelection.selectedId1 : '',
                equipOverview: [
                    {
                        spaces: state.equipOverview[0].spaces,
                        selectedSpace: sensorSelection?.selectedId1 ? sensorSelection.selectedId1 : '',
                    },
                    {
                        spaces: selectedBuilding?.rooms || [],
                        selectedSpace: sensorSelection?.selectedId2 ? sensorSelection.selectedId2 : '',
                    },
                    {
                        spaces: selectedSecondLevelRoom?.rooms || [],
                        selectedSpace: sensorSelection?.selectedId3 ? sensorSelection.selectedId3 : '',
                    },
                    {
                        spaces: selectedThirdLevelRoom?.rooms || [],
                        selectedSpace: sensorSelection?.selectedId4 ? sensorSelection.selectedId4 : '',
                    },
                    {
                        spaces: selectedFourthLevelRoom?.rooms || [],
                        selectedSpace: sensorSelection?.selectedId5 ? sensorSelection.selectedId5 : '',
                    },
                ],
                selectedRoomId: selectedSpace,
                breadCrumbs,
            };
        }
    }
};

const recursiveCreateSuggestions = (
    spaces: Space[],
    suggestions: SpaceSuggestion[],
    ids: string[],
    locations: string[]
) => {
    if (spaces.length === 0) {
        return;
    }
    spaces.forEach(r => {
        const i = [...ids];
        i.push(r.id);

        const l = [...locations];
        l.push(r.name);

        const suggestion: SpaceSuggestion = {
            name: r.name,
            ids: i,
            location: l,
        };
        suggestions.push(suggestion);

        recursiveCreateSuggestions(r.rooms, suggestions, i, l);
    });
};

const recursiveModuleSensorCheck = (spaces: Space[], sensors: Sensor[]) => {
    if (spaces.length === 0) {
        return;
    }

    spaces.forEach(r => {
        if (sensors.findIndex(f => f.roomRef === r.id) !== -1) {
            r.hasModuleSensor = true;
        }
        if (hasChildModuleSensorCheck(r.rooms, sensors, false)) {
            r.hasModuleSensor = true;
        }

        recursiveModuleSensorCheck(r.rooms, sensors);
    });
};

const hasChildModuleSensorCheck = (spaces: Space[], sensors: Sensor[], hasModuleSensor: boolean) => {
    let error = hasModuleSensor;
    if (spaces.length === 0 || error) {
        return error;
    }
    spaces.forEach(r => {
        if (sensors.findIndex(f => f.roomRef === r.id) !== -1) {
            error = true;
        }
        error = hasChildModuleSensorCheck(r.rooms, sensors, error);
    });

    return error;
};

const recursiveFilterRooms = (space: Space) => {
    if (space.rooms.length === 0) {
        return;
    }
    space.rooms = space.rooms.filter(space => space.hasModuleSensor);

    space.rooms.forEach(s => recursiveFilterRooms(s));
};
