import { addYears, differenceInHours, getUnixTime, isLeapYear, max, min, subDays } from 'date-fns';

import {
    createTickValues,
    calcAxisDates,
    mapSensorValue,
    getMax,
    getMin,
    mapSensorValueWithLabel,
} from 'components/Graph/graph.helpers';
import { GraphData, SensorValue } from 'components/Graph/types';

import { DateRange, DateSpan, isDaysTimespan } from 'utils/timePeriod';

import { KpiUnit, Sensor, SensorAverage, SensorType } from 'types';
import {
    BillableGraphData,
    ElectricityGraphData,
    TemperatureGraphData,
    OccupancyGraphData,
    Top5SensorData,
    ProductionGraphData,
} from './types';
import {
    Contract,
    ElectricityContract,
    GasContract,
    isElectricityContract,
    isGasContract,
} from 'views/authenticated/profile/types';

export const createBillableGraphData = (
    electricity: SensorAverage[],
    gas: SensorAverage[],
    timespan: string,
    action: string,
    customDate: DateRange,
    contracts: Contract[],
    building: string,
    sensorType: SensorType,
    isInGigajoules: boolean
): BillableGraphData => {
    const electricityContract = contracts.filter(
        c => isElectricityContract(c) && c.building === building
    ) as ElectricityContract[];

    const electricityData = [] as SensorValue[];
    const rawElectricityData = electricity.map(g => ({
        value: g.value,
        timestamp: getUnixTime(new Date(g.field)),
    }));

    electricity.forEach(eData => {
        const contractFound = electricityContract.find(
            eContract =>
                new Date(eData.field).getTime() >= new Date(eContract.startDate).getTime() &&
                new Date(eData.field).getTime() <= new Date(eContract.endDate).getTime()
        );
        if (contractFound) {
            electricityData.push({
                value:
                    new Date(eData.field).getHours() >= contractFound.startPeak &&
                    new Date(eData.field).getHours() <= contractFound.endPeak
                        ? eData.value *
                          (contractFound.highPrice + contractFound.serviceCost + contractFound.contractCost)
                        : eData.value *
                          (contractFound.lowPrice + contractFound.serviceCost + contractFound.contractCost),
                timestamp: getUnixTime(new Date(eData.field)),
            });
        }
    });

    const gasContract = contracts.filter(c => isGasContract(c) && c.building === building) as GasContract[];

    const gasData = [] as SensorValue[];
    const rawGasData = gas.map(g => ({
        value: g.value,
        timestamp: getUnixTime(new Date(g.field)),
    }));

    gas.forEach(eData => {
        const contractFound = gasContract.find(
            eContract =>
                new Date(eData.field).getTime() >= new Date(eContract.startDate).getTime() &&
                new Date(eData.field).getTime() <= new Date(eContract.endDate).getTime()
        );
        if (contractFound) {
            eData.value = contractFound.price;
            electricityData.push({
                value: eData.value * (contractFound.price + contractFound.serviceCost + contractFound.contractCost),
                timestamp: getUnixTime(new Date(eData.field)),
            });
        }
    });

    const mergedData = mergeData(electricityData, gasData);

    const tickValues = createTickValues(action, timespan, customDate);
    const tickAmount = timespan === DateSpan.WEEK ? 1 : 2;
    const tickFormat = isDaysTimespan(timespan) ? 'HH:mm' : 'MMM dd';
    const max = getMax(mergedData);
    const min = getMin(mergedData);
    const axisDates = isDaysTimespan(timespan) ? calcAxisDates(tickValues) : undefined;

    const graphData: BillableGraphData = {
        electricityData,
        gasData,
        tickValues,
        tickAmount,
        tickFormat,
        max,
        min,
        axisDates,
        timespan,
        rawElectricityData,
        rawGasData,
        sensorType,
        isInGigajoules,
    };
    return graphData;
};

export const createOccupancyGraphData = (
    electricity: SensorAverage[],
    gas: SensorAverage[],
    occupancy: SensorAverage[],
    timespan: string,
    action: string,
    customDate: DateRange
): OccupancyGraphData => {
    const electricityData = electricity.map(mapSensorValue);
    const gasData = gas.map(mapSensorValue);
    const occupancyData = occupancy.map(mapSensorValue);

    const tickValues = createTickValues(action, timespan, customDate);
    const tickAmount = timespan === DateSpan.WEEK ? 1 : 2;
    const tickFormat = isDaysTimespan(timespan) ? 'HH:mm' : 'MMM dd';
    const axisDates = isDaysTimespan(timespan) ? calcAxisDates(tickValues) : undefined;

    const graphData: OccupancyGraphData = {
        electricityData,
        gasData,
        occupancyData,
        tickValues,
        tickAmount,
        tickFormat,
        max: 0,
        min: 0,
        axisDates,
        timespan,
    };
    return graphData;
};

export const createProductionGraphData = (
    consumption: SensorAverage[],
    production: SensorAverage[],
    feedin: SensorAverage[],
    timespan: string,
    action: string,
    customDate: DateRange
): ProductionGraphData => {
    const consumptionData = consumption.map(mapSensorValue);
    const feedinData = feedin.map(mapSensorValue);
    const productionData = production.map(mapSensorValue);

    const mergedData = mergeData(consumptionData, productionData);

    const tickValues = createTickValues(action, timespan, customDate);
    const tickAmount = timespan === DateSpan.WEEK ? 1 : 2;
    const tickFormat = isDaysTimespan(timespan) ? 'HH:mm' : 'MMM dd';
    const max = getMax(mergedData);
    const min = getMin(mergedData);
    const axisDates = isDaysTimespan(timespan) ? calcAxisDates(tickValues) : undefined;

    const graphData: ProductionGraphData = {
        consumptionData,
        productionData,
        feedinData,
        tickValues,
        tickAmount,
        tickFormat,
        max,
        min,
        axisDates,
        timespan,
    };

    return graphData;
};

export const createElectricityGraphData = (
    top5: Top5SensorData[],
    totalElectricity: SensorAverage[],
    timespan: string,
    action: string,
    customDate: DateRange
): ElectricityGraphData => {
    const totalElectricityData = totalElectricity.map(mapSensorValue);
    const mappedData: SensorValue[][] = [];
    top5.forEach(d => mappedData.push(d.data.map(s => mapSensorValueWithLabel(s, d.name))));

    const tickValues = createTickValues(action, timespan, customDate);
    const tickAmount = timespan === DateSpan.WEEK ? 1 : 2;
    const tickFormat = isDaysTimespan(timespan) ? 'HH:mm' : 'MMM dd';
    const axisDates = isDaysTimespan(timespan) ? calcAxisDates(tickValues) : undefined;

    const graphData: ElectricityGraphData = {
        totalElectricityData,
        meter1: mappedData?.[0] || [],
        meter2: mappedData?.[1] || [],
        meter3: mappedData?.[2] || [],
        meter4: mappedData?.[3] || [],
        meter5: mappedData?.[4] || [],
        tickValues,
        tickAmount,
        tickFormat,
        max: 0,
        min: 0,
        axisDates,
        timespan,
    };

    return graphData;
};

export const createGasGraphData = (
    data: SensorAverage[],
    historicData: SensorAverage[],
    timespan: string,
    action: string,
    customDate: DateRange
) => {
    const sensorData = data.map(mapSensorValue);

    const historicSensorData = historicData.map(mapHistoricData);

    const tickValues = createTickValues(action, timespan, customDate);
    const tickAmount = timespan === DateSpan.WEEK ? 1 : 2;
    const tickFormat = isDaysTimespan(timespan) ? 'HH:mm' : 'MMM dd';
    const max = getMax(sensorData);
    const min = getMin(sensorData);
    const axisDates = isDaysTimespan(timespan) ? calcAxisDates(tickValues) : undefined;

    const graphData: GraphData = {
        data: sensorData,
        averageData: historicSensorData,
        tickValues,
        tickAmount,
        tickFormat,
        max,
        min,
        axisDates,
        timespan,
    };

    return graphData;
};

export const createTemperatureGraphData = (
    electricity: SensorAverage[],
    gas: SensorAverage[],
    temperature: SensorAverage[],
    timespan: string,
    action: string,
    customDate: DateRange,
    isInGigajoules: boolean
): TemperatureGraphData => {
    const electricityData = electricity.map(mapSensorValue);
    const gasData = gas.map(mapSensorValue);
    const temperatureData = temperature.map(mapSensorValue);

    const mergedData = mergeData(electricityData, gasData);

    const tickValues = createTickValues(action, timespan, customDate);
    const tickAmount = timespan === DateSpan.WEEK ? 1 : 2;
    const tickFormat = isDaysTimespan(timespan) ? 'HH:mm' : 'MMM dd';
    const max = getMax(mergedData);
    const min = getMin(mergedData);
    const axisDates = isDaysTimespan(timespan) ? calcAxisDates(tickValues) : undefined;

    const graphData: TemperatureGraphData = {
        electricityData,
        gasData,
        temperatureData,
        tickValues,
        tickAmount,
        tickFormat,
        max,
        min,
        axisDates,
        timespan,
        isInGigajoules,
    };
    return graphData;
};

const aggregateSameField = (total: SensorAverage[], current: SensorAverage) => {
    const index = total.findIndex(i => i.field === current.field);
    if (index === -1) {
        total.push(current);
    } else {
        total[index] = {
            field: current.field,
            value: total[index].value + current.value,
        };
    }
    return total;
};

const mapHistoricData = (sensor: SensorAverage): SensorValue => {
    const date = new Date(sensor.field);
    const dateNextYear = addYears(new Date(sensor.field), 1);
    return {
        value: sensor.value,
        timestamp:
            (isLeapYear(dateNextYear) && dateNextYear.getMonth() > 1) || (isLeapYear(date) && date.getMonth() < 2)
                ? getUnixTime(subDays(dateNextYear, 2))
                : getUnixTime(subDays(dateNextYear, 1)),
    };
};

export const summedValue = (sensorData: SensorValue[] | SensorAverage[], toFixed?: number) => {
    let total = 0;
    for (let i = sensorData.length - 1; i >= 0; i--) {
        total = total + sensorData[i].value;
    }

    return +total.toFixed(toFixed);
};

export const hourSpan = (tickValues: number[]) => {
    const firstHour = new Date(tickValues[0] * 1000);
    const lastHour = new Date(tickValues[tickValues.length - 1] * 1000);

    return differenceInHours(lastHour, firstHour);
};

export const numberFormatter = (n: number, digits: number): string => {
    return `${n > 999 ? Number((n / 1000).toFixed(digits)) : +n}`;
};

export const totalNumberFormatter = (total: number, n: number, digits: number): string => {
    return `${total > 999 ? Number((n / 1000).toFixed(digits)) : n}`;
};

const mergeData = (...args: SensorValue[][]): SensorValue[] => {
    let newArr: SensorValue[] = [];

    const len = args.length;
    for (let i = 0; i < len; i += 1) {
        newArr = newArr.concat(args[i]);
    }

    newArr.sort((a, b) => (a.timestamp < b.timestamp ? -1 : a.timestamp > b.timestamp ? 1 : 0));

    return newArr;
};

export const getLastKpiUpdateTimestamp = (sensors: Sensor[]) => {
    const dates: Date[] = [];
    sensors.forEach(s => dates.push(new Date(s.latestKpi.time)));
    if (!dates.length) {
        return '';
    }
    return max(dates);
};

export const getFirstDataTimestamp = (sensors: Sensor[]) => {
    const dates: Date[] = [];
    sensors.forEach(s => dates.push(new Date(s.dataFlowStart)));

    if (!dates.length) {
        return '';
    }
    return min(dates);
};

export const getUnit = (consumption: number, isGj: boolean): string => {
    if (isGj) return consumption >= 999 ? KpiUnit.TJ : KpiUnit.GJ;
    return consumption > 999 ? KpiUnit.MWH : KpiUnit.KWH;
};
