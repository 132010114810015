import { Equip } from 'views/authenticated/types';

export enum ModuleType {
    AIR = 'Air',
    WATER = 'Water',
    ENERGY = 'Energy',
    OCCUPANCY = 'Occupancy',
    MOBILITY = 'Mobility',
    SENSORS = 'Sensors',
    FEEDBACK = 'Feedback',
    GAS = 'Gas',
}

export enum AnomalyType {
    INSIGHTS = 'Insights',
    INCIDENTS = 'Incidents',
}

export enum AirMetricsQualityAction {
    POOR = 'InSufficient',
    ACCEPTABLE = 'Moderate',
    GOOD = 'Sufficient',
    UNKNOWN = 'Unknown',
    ANY = '',
}

export enum AirMetricsAction {
    TEMPERATURE = 'temperature',
    RELATIVE_HUMIDITY = 'humidity',
    CO2 = 'CO2',
}

export interface IncidentData {
    data: Incident[];
    types: string[];
}

export interface Incident {
    pointId: string;
    name: string;
    siteName: string;
    equipsName: string[];
    equipId: string;
    value: number;
    valueBefore: number;
    // UTC date-time to be precise
    timestamp: string;
    timestampStart: string;
    duration: string;
    anomalyType: AnomalyType;
    anomalySubType: AnomalySubType;
    moduleType: ModuleType;
    thresholdFrom?: string;
    thresholdTo?: string;
    total?: number;
    type: SensorType;
    sensorIds?: string[];
}

export type AnomalySubType =
    | AnomalySubTypeAir
    | AnomalySubTypeOccupancy
    | AnomalySubTypeEnergy
    | AnomalySubTypeWater
    | AnomalySubTypeSensor;

export enum AnomalySubTypeAir {
    AIR_QUALITY = 'AirQuality',
    RAPID_TEMPERATURE_INCREASE = 'RapidTemperatureIncrease',
    HIGH_TEMPERATURE = 'HighTemperature',
    ABNORMAL_TEMPERATURE = 'AbnormalTemperature',
    ABNORMAL_HUMIDITY = 'AbnormalHumidity',
}

export enum AnomalySubTypeEnergy {
    ENERGY_ABOVE_AVERAGE_CONSUMPTION = 'EnergyAboveAverageConsumption',
}

export enum AnomalySubTypeWater {
    WATER_LOW_OCCUPANCY_CONSUMPTION = 'WaterLowOccupancyConsumption',
    WATER_ABOVE_AVERAGE_CONSUMPTION = 'WaterAboveAverageConsumption',
    WATER_ZERO_CONSUMPTION = 'WaterZeroConsumption',
    WATER_HIGH_AIR_TEMPERATURE = 'WaterHighAirTemperature',
}

export enum AnomalySubTypeOccupancy {
    MOTION_DETECTED = 'MotionDetected',
    OCCUPANCY_PERCENTAGE = 'OccupancyPercentage',
}

export enum AnomalySubTypeSensor {
    ANY = 'Any',
    NO_DATA = 'DataFlowStopped',
    LOW_BATTERY = 'LowBattery',
}

// export enum EnergySubType {
//     ELECTRICITY = 'Electricity',
//     COOLING = 'Cooling',
//     HEATING = 'Heating',
// }

export interface SensorData {
    value: number;
    time: string;
}

export interface ScoreData {
    score: number;
    time: string;
}

export interface Dimension {
    width: number;
    height: number;
}

export enum SensorAvgFilter {
    YEAR = 'Year',
    MONTH = 'Month',
    WEEK_OF_YEAR = 'WeekOfYear',
    WEEK_OF_MONTH = 'WeekOfMonth',
    DAY_OF_WEEK = 'DayOfWeek',
    DAY_OF_YEAR = 'DayOfYear',
    DAY_OF_MONTH = 'DayOfMonth',
    DAY = 'Day',
    HOUR = 'Hour',
    HOUR_OF_DAY = 'HourOfDay',
    HOUR_OF_DAY_OF_WEEK = 'HourOfDayOfWeek',
}

export enum SensorAvgInterval {
    UNKNOWN = 'Unknown',
    INTERVAL_15 = 'Interval15',
    INTERVAL_20 = 'Interval20',
    INTERVAL_30 = 'Interval30',
}

export interface Sensor extends Equip {
    modificationDate: string;
    identifier: string;
    type: SensorType;
    unit: KpiUnit;
    kind: string;
    buildingRef: string;
    roomRef: string;
    latestKpi: KPI;
    lastHourAvgKpi: KPI;
    buildingName: string;
    roomName: string;
    subMeterRoomRef?: string;
    isMain: boolean;
    subType: SensorSubType;
    dataFlowStart: string;
}

export interface KPI {
    kpi: KPIType;
    value: number;
    time: string;
}

export enum KPIType {
    INSUFFICIENT = 'InSufficient',
    MODERATE = 'Moderate',
    SUFFICIENT = 'Sufficient',
    UNKNOWN = 'Unknown',
}

export enum KpiUnit {
    KG = 'kg',
    T = 't',
    PPM = 'ppm',
    C = 'C',
    CELSIUS = '°C',
    COUNT = 'count',
    RH = '%RH',
    M3 = 'm3',
    M3H = 'm3/h',
    LX = 'lx',
    V = 'V',
    MWH = 'MWh',
    KWH_UPPERCASE = 'KWH',
    KWH = 'kWh',
    WH = 'Wh',
    MA = 'mA',
    MAH = 'mAh',
    SCORE = '',
    L = 'L',
    LITER = 'Liter',
    PERCENTAGE = '%',
    EURO = '€',
    GJ = 'Gj',
    TJ = 'Tj',
}

export enum SensorType {
    CO2 = 'CO2',
    TEMPERATURE = 'temperature',
    TEMP = 'temp',
    HUMIDITY = 'humidity',
    WATER = 'water',
    ELECTRICITY = 'electricity',
    CURRENT = 'current',
    CURRENT_INDEX = 'currentIndex',
    COOLING = 'cooling',
    HEATING = 'heating',
    GAS = 'gas',
    BATTERY_VOLTAGE = 'batteryVoltage',
    MOTION = 'motion',
    ELEC_KWH = 'elecKwh',
    KWH = 'kWh',
    NOISE_LEVEL = 'noiselevel',
    MOVEMENT = 'movement',
    LIGHT_INTENSITY = 'lightintensity',
    ILLUMINANCE = 'illuminance',
    CCQ = 'cumulative climate quality',
    ELECTRICITY_DAILY = 'electricity_Daily',
    ELECTRICITY_MONTHLY = 'electricity_Monthly',
}

export enum SensorSubType {
    EMPTY = '',
    CONSUMPTION = 'consumption',
    PRODUCTION = 'production',
    FEEDIN = 'feedin',
    COUNT = 'count',
}

export interface SensorAverage {
    value: number;
    field: string;
}

export interface Score {
    roomId: string;
    data: ScoreData[];
}

export interface WeatherData {
    t: number;
    h: number;
    p: number;
    d: string;
}

export interface WeatherResponse {
    city: string;
    d: 'date';
    data: WeatherData[];
    h: 'humidity';
    p: 'pressure';
    t: 'temperature';
}

export interface BagData {
    woonplaats: string;
    oppervlakte: number;
    adresseerbaarObjectIdentificatie: string;
    status: string;
    oorspronkelijkBouwjaar: string;
    gebruiksdoelen: string[];
    energy: Energy[];
}

interface Energy {
    gebouwklasse: string;
    labelLetter: string;
    opnamedatum: string;
    status: string;
    opnametype: string;
    postcode: string;
    huisnummer: number;
    huisletter: string;
}

export interface OccupancyScore {
    roomId: string;
    data: OccupancyData[];
}

export interface OccupancyScoreAvg {
    roomId: string;
    data: SensorAverage[];
}

export interface OccupancyData {
    date: string;
    occupied: number;
    buildingId?: string;
}

export interface OccupancyDataAvg {
    time: string;
    occupied: number;
}

export interface SpaceSumEnergyConsumption {
    buildingId: string;
    roomId?: string;
    rooms?: SpaceSumEnergyConsumption[];
    unit?: string;
    energyType: number;
    energySubType: number;
    value: number;
}
