import React, { useEffect, useReducer, useState } from 'react';

import ModulesSubMenu, { navItemsEnergy } from 'components/Submenu/ModulesSubMenu';
import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';
import EnergyStatsDataPane from 'components/DataPane/statistics/energy/EnergyStatsDataPane';
import { SpaceSuggestion } from 'components/SearchAutoComplete';
import SensorSelector from 'components/SensorSelector';
import { createSensorSelection, getSelectedSpace } from '../sensors/SensorsOverviewView';

import { useSensorsByModulesData } from 'hooks/api/useSensorsByModulesData';
import { useRoomsUsageData } from 'hooks/api/useRoomsUsageData';
import { useBuildingUsageData } from 'hooks/api/useBuildingUsageData';
import { useTenant } from 'context/TenantContext';

import { ModuleType, Sensor, SensorSubType, SensorType, SpaceSumEnergyConsumption } from 'types';
import { SensorSelection, EnergyConstants, Space, SensorLookup } from '../types';
import { EnergyStatsAction, EnergyStatsState } from './types';

import { DateSpan, createFromToDate } from 'utils/timePeriod';
import EnergyStatisticsSubMenu from 'components/Submenu/EnergyStatisticsSubMenu';

const DEFAULT_ELECTRICITY_CONSTANTS: EnergyConstants = { co2Emission: 1, price: 1 };
const DEFAULT_GAS_CONSTANTS: EnergyConstants = { co2Emission: 1, price: 1 };
const emptyList = { spaces: [] as Space[], selectedSpace: '' };

const EnergyStatisticsView: React.FunctionComponent = () => {
    const { getBuildingData, contracts, ewattchMeta } = useTenant();
    const [state, dispatch] = useReducer(EnergyStatisticsReducer, initialState);
    const [isExpanded, setIsExpanded] = useState(false);
    const {
        buildingConstants,
        breadCrumbs,
        city,
        selectedMainElectricitySensors,
        selectedSubElectricitySensors,
        selectedGasSensors,
        selectedProductionSensors,
        selectedFeedinSensors,
        selectedOccupancySensors,
        selectedEwattchSensors,
        equipOverview,
        suggestions,
        buildingStructure,
        selectedRoom,
        from,
        to,
        selectedSensorType,
        hasCoolingHeatingSensors,
    } = state;

    const { data, isLoading } = useSensorsByModulesData([ModuleType.ENERGY, ModuleType.OCCUPANCY]);
    const { data: roomUsage } = useRoomsUsageData(buildingStructure, from, to, selectedSensorType);
    const { data: buildingUsage } = useBuildingUsageData(buildingStructure, from, to, selectedSensorType);

    useEffect(() => {
        if (data) {
            const sensorsEnergy = data[0];
            const sensorsOccupancy = data[1];

            dispatch({
                type: 'INIT_SENSORS',
                buildingData: getBuildingData(),
                sensorDataEnergy: sensorsEnergy,
                sensorDataOccupancy: sensorsOccupancy,
                ewattchMeta: ewattchMeta,
            });
        }
    }, [data]);

    useEffect(() => {
        if (buildingUsage && buildingStructure.length) {
            buildingStructure.forEach(building => {
                building.value = 0;
            });

            buildingUsage.usage.forEach(result => {
                buildingStructure.forEach(building => {
                    if (result.buildingId === building.id) {
                        building.value = (building.value || 0) + (result.value || 0);
                    }
                });
            });

            buildingStructure.forEach(building => {
                building.value = building.value ? +building.value.toFixed(0) : 0;
            });

            dispatch({
                type: 'ADD_USAGE_DATA',
                buildingData: buildingStructure,
            });
        }
    }, [buildingUsage, buildingStructure]);

    useEffect(() => {
        if (roomUsage && buildingStructure.length) {
            buildingStructure.forEach(building => {
                const usage = roomUsage.usage.find(r => r.buildingId === building.id);
                recursiveProcessRooms(building.rooms, usage.rooms);
            });

            dispatch({
                type: 'ADD_USAGE_DATA',
                buildingData: buildingStructure,
            });
        }
    }, [roomUsage, buildingStructure]);

    return (
        <>
            <ModulesSubMenu
                subNavItems={navItemsEnergy}
                suggestions={suggestions}
                breadCrumbs={isExpanded ? breadCrumbs : []}
                onSearch={selectedSpace =>
                    dispatch({
                        type: 'SEARCH_SENSOR',
                        selectedSpace: selectedSpace,
                    })
                }
            />
            {hasCoolingHeatingSensors && (
                <EnergyStatisticsSubMenu
                    selectedSensorType={selectedSensorType}
                    onSelectSensorType={selectedType =>
                        dispatch({ type: 'SELECT_SENSOR_TYPE', selectedType: selectedType })
                    }
                />
            )}

            {isLoading && <LoadingOverlay />}

            {Boolean(equipOverview.length) && (
                <SensorSelector
                    equips={equipOverview}
                    onEquipSelect={(selectAction, roomId) => dispatch({ type: selectAction, spaceId: roomId })}
                    collapsed={Boolean(
                        [
                            ...selectedMainElectricitySensors,
                            ...selectedSubElectricitySensors,
                            ...selectedGasSensors,
                            ...selectedEwattchSensors,
                        ].length
                    )}
                />
            )}
            {Boolean(
                [
                    ...selectedMainElectricitySensors,
                    ...selectedSubElectricitySensors,
                    ...selectedGasSensors,
                    ...selectedEwattchSensors,
                ].length
            ) && (
                <EnergyStatsDataPane
                    buildingName={breadCrumbs[0]}
                    contracts={contracts}
                    buildingConstants={buildingConstants}
                    selectedRoom={selectedRoom}
                    city={city}
                    mainElectricitySensors={selectedMainElectricitySensors}
                    subElectrictySensors={selectedSubElectricitySensors}
                    ewattchSensors={selectedEwattchSensors}
                    gasSensors={selectedGasSensors}
                    productionSensors={selectedProductionSensors}
                    feedinSensors={selectedFeedinSensors}
                    occupancySensors={selectedOccupancySensors}
                    onExpand={setIsExpanded}
                    onDateSelect={(action, timespan, customDate) =>
                        dispatch({ type: 'CHANGE_DATE_SPAN', action, timespan, customDate })
                    }
                />
            )}
        </>
    );
};

export default EnergyStatisticsView;

export const initialState: EnergyStatsState = {
    energySensors: [],
    occupancySensors: [],
    buildingData: [],
    ewattchMeta: [],
    selectedEwattchSensors: [],
    buildingConstants: {
        gasConstants: DEFAULT_GAS_CONSTANTS,
        electricityConstants: DEFAULT_ELECTRICITY_CONSTANTS,
    },
    city: '',
    suggestions: [],
    sensors: [],
    equipOverview: [],
    buildingStructure: [],
    selectedRoom: '',
    selectedGasSensors: [],
    selectedMainElectricitySensors: [],
    selectedSubElectricitySensors: [],
    selectedProductionSensors: [],
    selectedFeedinSensors: [],
    selectedOccupancySensors: [],
    breadCrumbs: [],
    from: createFromToDate(DateSpan.TWO_DAYS).from,
    to: createFromToDate(DateSpan.TWO_DAYS).to,
    selectedSensorType: SensorType.ELECTRICITY,
    hasCoolingHeatingSensors: false,
    sensorLookup: null,
};

const EnergyStatisticsReducer = (state: EnergyStatsState, action: EnergyStatsAction): EnergyStatsState => {
    switch (action.type) {
        case 'INIT_SENSORS': {
            const sensorSelection = localStorage.getItem('energy-stats-selection');
            const parsedSelection: SensorSelection | null = sensorSelection ? JSON.parse(sensorSelection) : null;
            const sensors = [
                ...action.sensorDataEnergy.filter(
                    s =>
                        s.type === state.selectedSensorType ||
                        (state.selectedSensorType === SensorType.ELECTRICITY && s.type === SensorType.GAS)
                ),
                ...action.sensorDataOccupancy,
            ];

            const initialBuildingData: Space[] = JSON.parse(JSON.stringify(action.buildingData));

            const filteredBuildingData = initialBuildingData
                .filter(building => Boolean(sensors.filter(f => f.buildingRef === building.id).length))
                .map(obj => ({ ...obj }));

            filteredBuildingData.forEach(building => {
                recursiveModuleSensorCheck(
                    building.rooms,
                    action.sensorDataEnergy.filter(s => s.type !== SensorType.HEATING && s.type !== SensorType.COOLING)
                );
            });

            filteredBuildingData.forEach(building => {
                recursiveFilterRooms(building);
            });

            const suggestions: SpaceSuggestion[] = [];
            filteredBuildingData.forEach(building => {
                const location = [building.name];
                const ids = [building.id];
                const suggestion: SpaceSuggestion = {
                    name: building.name,
                    ids,
                    location,
                };
                suggestions.push(suggestion);
                recursiveCreateSuggestions(building.rooms, suggestions, ids, location);
            });

            const selectedBuilding = filteredBuildingData.find(i => i.id === parsedSelection?.selectedId1);
            const selectedSecondLevelRoom = selectedBuilding?.rooms.find(i => i.id === parsedSelection?.selectedId2);
            const selectedThirdLevelRoom = selectedSecondLevelRoom?.rooms.find(
                i => i.id === parsedSelection?.selectedId3
            );
            const selectedFourthLevelRoom = selectedThirdLevelRoom?.rooms.find(
                i => i.id === parsedSelection?.selectedId4
            );
            const selectedFifthLevelRoom = selectedFourthLevelRoom?.rooms.find(
                i => i.id === parsedSelection?.selectedId5
            );
            const breadCrumbs = [
                selectedBuilding?.name,
                selectedSecondLevelRoom?.name,
                selectedThirdLevelRoom?.name,
                selectedFourthLevelRoom?.name,
                selectedFifthLevelRoom?.name,
            ].filter((i): i is string => {
                return typeof i === 'string';
            });

            const selectedBuildingId = parsedSelection?.selectedId1 ? parsedSelection.selectedId1 : '';
            const selectedRoomId = parsedSelection?.selectedId5
                ? parsedSelection.selectedId5
                : parsedSelection?.selectedId4
                ? parsedSelection.selectedId4
                : parsedSelection?.selectedId3
                ? parsedSelection.selectedId3
                : parsedSelection?.selectedId2
                ? parsedSelection.selectedId2
                : '';

            const equipOverview = [
                {
                    spaces: filteredBuildingData || [],
                    selectedSpace: parsedSelection?.selectedId1 ? parsedSelection.selectedId1 : '',
                },
                {
                    spaces: selectedBuilding?.rooms || [],
                    selectedSpace: parsedSelection?.selectedId2 ? parsedSelection.selectedId2 : '',
                },
                {
                    spaces: selectedSecondLevelRoom?.rooms || [],
                    selectedSpace: parsedSelection?.selectedId3 ? parsedSelection.selectedId3 : '',
                },
                {
                    spaces: selectedThirdLevelRoom?.rooms || [],
                    selectedSpace: parsedSelection?.selectedId4 ? parsedSelection.selectedId4 : '',
                },
                {
                    spaces: selectedFourthLevelRoom?.rooms || [],
                    selectedSpace: parsedSelection?.selectedId5 ? parsedSelection.selectedId5 : '',
                },
            ];
            const sensorLookup = buildSensorLookup(filteredBuildingData, sensors);
            const allRelevantSensors = sensorLookup[selectedRoomId]
                ? sensors.filter(sensor => sensorLookup[selectedRoomId].has(sensor.id))
                : [];

            return {
                ...state,
                energySensors: action.sensorDataEnergy,
                occupancySensors: action.sensorDataOccupancy,
                buildingData: action.buildingData,
                ewattchMeta: action.ewattchMeta,
                suggestions: suggestions,
                sensors,
                sensorLookup,
                city: selectedBuilding?.openWeather || '',
                buildingConstants: {
                    gasConstants: selectedBuilding?.gasConstants || DEFAULT_GAS_CONSTANTS,
                    electricityConstants: selectedBuilding?.electricityConstants || DEFAULT_ELECTRICITY_CONSTANTS,
                },
                buildingStructure: filteredBuildingData,
                equipOverview,
                selectedMainElectricitySensors:
                    selectedRoomId !== ''
                        ? allRelevantSensors.filter(
                              s =>
                                  s.subType === SensorSubType.CONSUMPTION &&
                                  s.type === SensorType.ELECTRICITY &&
                                  s.isMain
                          )
                        : sensors.filter(
                              s =>
                                  s.buildingRef === selectedBuildingId &&
                                  s.subType === SensorSubType.CONSUMPTION &&
                                  s.type === SensorType.ELECTRICITY &&
                                  s.isMain
                          ),
                selectedSubElectricitySensors:
                    selectedRoomId !== ''
                        ? allRelevantSensors.filter(
                              s =>
                                  s.subType === SensorSubType.CONSUMPTION &&
                                  s.type === SensorType.ELECTRICITY &&
                                  !s.isMain
                          )
                        : sensors.filter(
                              s =>
                                  s.buildingRef === selectedBuildingId &&
                                  s.subType === SensorSubType.CONSUMPTION &&
                                  s.type === SensorType.ELECTRICITY &&
                                  !s.isMain
                          ),
                selectedGasSensors:
                    selectedRoomId !== ''
                        ? allRelevantSensors.filter(
                              s => s.subType === SensorSubType.CONSUMPTION && s.type === SensorType.GAS
                          )
                        : sensors.filter(
                              s =>
                                  s.buildingRef === selectedBuildingId &&
                                  s.subType === SensorSubType.CONSUMPTION &&
                                  s.type === SensorType.GAS
                          ),
                selectedProductionSensors:
                    selectedRoomId !== ''
                        ? []
                        : sensors.filter(
                              s =>
                                  s.buildingRef === selectedBuildingId &&
                                  s.type === state.selectedSensorType &&
                                  s.subType === SensorSubType.PRODUCTION
                          ),
                selectedFeedinSensors:
                    selectedRoomId !== ''
                        ? []
                        : sensors.filter(
                              s =>
                                  s.buildingRef === selectedBuildingId &&
                                  s.type === state.selectedSensorType &&
                                  s.subType === SensorSubType.FEEDIN
                          ),
                selectedOccupancySensors:
                    selectedRoomId !== ''
                        ? allRelevantSensors.filter(s => s.type === SensorType.MOTION)
                        : sensors.filter(s => s.buildingRef === selectedBuildingId && s.type === SensorType.MOTION),
                selectedEwattchSensors:
                    selectedRoomId !== ''
                        ? action.ewattchMeta.filter(s => s.equipId === selectedRoomId)
                        : action.ewattchMeta.filter(s => s.sensorId === selectedBuildingId),
                selectedRoom: selectedRoomId,
                breadCrumbs,
                hasCoolingHeatingSensors:
                    !!action.sensorDataEnergy.find(
                        s => s.type === SensorType.COOLING || s.type === SensorType.HEATING
                    ) || false,
            };
        }
        case 'SELECT_SPACE1': {
            localStorage.setItem(
                'energy-stats-selection',
                JSON.stringify({
                    selectedId1: action.spaceId,
                })
            );
            const selectedBuilding = state.equipOverview[0].spaces.find(i => i.id === action.spaceId);

            const breadCrumbs = [state.equipOverview[0].spaces.find(i => i.id === action.spaceId)?.name].filter(
                (i): i is string => {
                    return typeof i === 'string';
                }
            );

            return {
                ...state,
                city: selectedBuilding?.openWeather || '',
                buildingConstants: {
                    gasConstants: selectedBuilding?.gasConstants || DEFAULT_GAS_CONSTANTS,
                    electricityConstants: selectedBuilding?.electricityConstants || DEFAULT_ELECTRICITY_CONSTANTS,
                },
                equipOverview: [
                    {
                        spaces: state.equipOverview[0].spaces,
                        selectedSpace: action.spaceId,
                    },
                    {
                        spaces: selectedBuilding?.rooms || [],
                        selectedSpace: '',
                    },
                    emptyList,
                    emptyList,
                    emptyList,
                ],
                selectedMainElectricitySensors:
                    state.selectedSensorType === SensorType.ELECTRICITY
                        ? state.sensors.filter(
                              s =>
                                  s.buildingRef === action.spaceId &&
                                  s.subType === SensorSubType.CONSUMPTION &&
                                  s.type === SensorType.ELECTRICITY &&
                                  s.isMain
                          )
                        : [],
                selectedSubElectricitySensors: state.sensors.filter(
                    s =>
                        s.buildingRef === action.spaceId &&
                        s.subType === SensorSubType.CONSUMPTION &&
                        s.type === SensorType.ELECTRICITY &&
                        !s.isMain
                ),
                selectedGasSensors:
                    state.selectedSensorType === SensorType.ELECTRICITY
                        ? state.sensors.filter(
                              s =>
                                  s.buildingRef === action.spaceId &&
                                  s.subType === SensorSubType.CONSUMPTION &&
                                  s.type === SensorType.GAS
                          )
                        : [],
                selectedProductionSensors:
                    state.selectedSensorType === SensorType.ELECTRICITY
                        ? state.sensors.filter(
                              s =>
                                  s.buildingRef === action.spaceId &&
                                  s.type === state.selectedSensorType &&
                                  s.subType === SensorSubType.PRODUCTION
                          )
                        : [],
                selectedFeedinSensors:
                    state.selectedSensorType === SensorType.ELECTRICITY
                        ? state.sensors.filter(
                              s =>
                                  s.buildingRef === action.spaceId &&
                                  s.type === state.selectedSensorType &&
                                  s.subType === SensorSubType.FEEDIN
                          )
                        : [],
                selectedOccupancySensors: state.sensors.filter(
                    s => s.buildingRef === action.spaceId && s.type === SensorType.MOTION
                ),
                selectedEwattchSensors: state.ewattchMeta.filter(s => s.siteId === action.spaceId),
                selectedRoom: '',
                breadCrumbs,
            };
        }
        case 'SELECT_SPACE2': {
            localStorage.setItem(
                'energy-stats-selection',
                JSON.stringify({
                    selectedId1: state.equipOverview[0].selectedSpace,
                    selectedId2: action.spaceId,
                })
            );

            const breadCrumbs = [
                state.equipOverview[0].spaces.find(i => i.id === state.equipOverview[0].selectedSpace)?.name,
                state.equipOverview[1].spaces.find(i => i.id === action.spaceId)?.name,
            ].filter((i): i is string => {
                return typeof i === 'string';
            });

            const allRelevantSensors = state.sensorLookup[action.spaceId]
                ? state.sensors.filter(sensor => state.sensorLookup[action.spaceId].has(sensor.id))
                : [];

            return {
                ...state,
                equipOverview: [
                    state.equipOverview[0],
                    {
                        spaces: state.equipOverview[1].spaces,
                        selectedSpace: action.spaceId,
                    },
                    {
                        spaces: state.equipOverview[1].spaces.find(i => i.id === action.spaceId)?.rooms || [],
                        selectedSpace: '',
                    },
                    emptyList,
                    emptyList,
                ],
                selectedMainElectricitySensors:
                    state.selectedSensorType === SensorType.ELECTRICITY
                        ? allRelevantSensors.filter(
                              s =>
                                  s.subType === SensorSubType.CONSUMPTION &&
                                  s.type === SensorType.ELECTRICITY &&
                                  s.isMain
                          )
                        : [],
                selectedSubElectricitySensors: allRelevantSensors.filter(
                    s =>
                        (state.selectedSensorType === SensorType.ELECTRICITY
                            ? s.subType === SensorSubType.CONSUMPTION && s.type === state.selectedSensorType
                            : s.type === state.selectedSensorType) && !s.isMain
                ),
                selectedGasSensors:
                    state.selectedSensorType === SensorType.ELECTRICITY
                        ? allRelevantSensors.filter(
                              s => s.subType === SensorSubType.CONSUMPTION && s.type === SensorType.GAS
                          )
                        : [],
                selectedProductionSensors: [],
                selectedFeedinSensors: [],
                selectedOccupancySensors: allRelevantSensors.filter(s => s.type === SensorType.MOTION),
                selectedEwattchSensors: state.ewattchMeta.filter(s => s.equipId === action.spaceId),
                selectedRoom: action.spaceId,
                breadCrumbs,
            };
        }
        case 'SELECT_SPACE3': {
            localStorage.setItem(
                'energy-stats-selection',
                JSON.stringify({
                    selectedId1: state.equipOverview[0].selectedSpace,
                    selectedId2: state.equipOverview[1].selectedSpace,
                    selectedId3: action.spaceId,
                })
            );

            const breadCrumbs = [
                state.equipOverview[0].spaces.find(i => i.id === state.equipOverview[0].selectedSpace)?.name,
                state.equipOverview[1].spaces.find(i => i.id === state.equipOverview[1].selectedSpace)?.name,
                state.equipOverview[2].spaces.find(i => i.id === action.spaceId)?.name,
            ].filter((i): i is string => {
                return typeof i === 'string';
            });

            const allRelevantSensors = state.sensorLookup[action.spaceId]
                ? state.sensors.filter(sensor => state.sensorLookup[action.spaceId].has(sensor.id))
                : [];

            return {
                ...state,
                equipOverview: [
                    state.equipOverview[0],
                    state.equipOverview[1],
                    {
                        spaces: state.equipOverview[2].spaces,
                        selectedSpace: action.spaceId,
                    },
                    {
                        spaces: state.equipOverview[2].spaces.find(i => i.id === action.spaceId)?.rooms || [],
                        selectedSpace: '',
                    },
                    emptyList,
                ],
                selectedMainElectricitySensors:
                    state.selectedSensorType === SensorType.ELECTRICITY
                        ? allRelevantSensors.filter(
                              s =>
                                  s.subType === SensorSubType.CONSUMPTION &&
                                  s.type === SensorType.ELECTRICITY &&
                                  s.isMain
                          )
                        : [],
                selectedSubElectricitySensors: allRelevantSensors.filter(
                    s =>
                        (state.selectedSensorType === SensorType.ELECTRICITY
                            ? s.subType === SensorSubType.CONSUMPTION && s.type === state.selectedSensorType
                            : s.type === state.selectedSensorType) && !s.isMain
                ),
                selectedGasSensors:
                    state.selectedSensorType === SensorType.ELECTRICITY
                        ? allRelevantSensors.filter(
                              s => s.subType === SensorSubType.CONSUMPTION && s.type === SensorType.GAS
                          )
                        : [],
                selectedProductionSensors: [],
                selectedFeedinSensors: [],
                selectedOccupancySensors: allRelevantSensors.filter(s => s.type === SensorType.MOTION),
                selectedEwattchSensors: state.ewattchMeta.filter(s => s.equipId === action.spaceId),
                selectedRoom: action.spaceId,
                breadCrumbs,
            };
        }
        case 'SELECT_SPACE4': {
            localStorage.setItem(
                'energy-stats-selection',
                JSON.stringify({
                    selectedId1: state.equipOverview[0].selectedSpace,
                    selectedId2: state.equipOverview[1].selectedSpace,
                    selectedId3: state.equipOverview[2].selectedSpace,
                    selectedId4: action.spaceId,
                })
            );

            const breadCrumbs = [
                state.equipOverview[0].spaces.find(i => i.id === state.equipOverview[0].selectedSpace)?.name,
                state.equipOverview[1].spaces.find(i => i.id === state.equipOverview[1].selectedSpace)?.name,
                state.equipOverview[2].spaces.find(i => i.id === state.equipOverview[2].selectedSpace)?.name,
                state.equipOverview[3].spaces.find(i => i.id === action.spaceId)?.name,
            ].filter((i): i is string => {
                return typeof i === 'string';
            });

            const allRelevantSensors = state.sensorLookup[action.spaceId]
                ? state.sensors.filter(sensor => state.sensorLookup[action.spaceId].has(sensor.id))
                : [];

            return {
                ...state,
                equipOverview: [
                    state.equipOverview[0],
                    state.equipOverview[1],
                    state.equipOverview[2],
                    {
                        spaces: state.equipOverview[3].spaces,
                        selectedSpace: action.spaceId,
                    },
                    {
                        spaces: state.equipOverview[3].spaces.find(i => i.id === action.spaceId)?.rooms || [],
                        selectedSpace: '',
                    },
                ],
                selectedMainElectricitySensors:
                    state.selectedSensorType === SensorType.ELECTRICITY
                        ? allRelevantSensors.filter(
                              s =>
                                  s.subType === SensorSubType.CONSUMPTION &&
                                  s.type === SensorType.ELECTRICITY &&
                                  s.isMain
                          )
                        : [],
                selectedSubElectricitySensors: allRelevantSensors.filter(
                    s =>
                        (state.selectedSensorType === SensorType.ELECTRICITY
                            ? s.subType === SensorSubType.CONSUMPTION && s.type === state.selectedSensorType
                            : s.type === state.selectedSensorType) && !s.isMain
                ),
                selectedGasSensors:
                    state.selectedSensorType === SensorType.ELECTRICITY
                        ? allRelevantSensors.filter(
                              s => s.subType === SensorSubType.CONSUMPTION && s.type === SensorType.GAS
                          )
                        : [],
                selectedProductionSensors: [],
                selectedFeedinSensors: [],
                selectedOccupancySensors: allRelevantSensors.filter(s => s.type === SensorType.MOTION),
                selectedEwattchSensors: state.ewattchMeta.filter(s => s.equipId === action.spaceId),
                selectedRoom: action.spaceId,
                breadCrumbs,
            };
        }
        case 'SELECT_SPACE5': {
            localStorage.setItem(
                'energy-stats-selection',
                JSON.stringify({
                    selectedId1: state.equipOverview[0].selectedSpace,
                    selectedId2: state.equipOverview[1].selectedSpace,
                    selectedId3: state.equipOverview[2].selectedSpace,
                    selectedId4: state.equipOverview[3].selectedSpace,
                    selectedId5: action.spaceId,
                })
            );

            const breadCrumbs = [
                state.equipOverview[0].spaces.find(i => i.id === state.equipOverview[0].selectedSpace)?.name,
                state.equipOverview[1].spaces.find(i => i.id === state.equipOverview[1].selectedSpace)?.name,
                state.equipOverview[2].spaces.find(i => i.id === state.equipOverview[2].selectedSpace)?.name,
                state.equipOverview[3].spaces.find(i => i.id === state.equipOverview[3].selectedSpace)?.name,
                state.equipOverview[4].spaces.find(i => i.id === action.spaceId)?.name,
            ].filter((i): i is string => {
                return typeof i === 'string';
            });

            return {
                ...state,
                equipOverview: [
                    state.equipOverview[0],
                    state.equipOverview[1],
                    state.equipOverview[2],
                    state.equipOverview[3],
                    {
                        spaces: state.equipOverview[4].spaces,
                        selectedSpace: action.spaceId,
                    },
                ],
                selectedMainElectricitySensors:
                    state.selectedSensorType === SensorType.ELECTRICITY
                        ? state.sensors.filter(
                              s =>
                                  s.roomRef === action.spaceId &&
                                  s.subType === SensorSubType.CONSUMPTION &&
                                  s.type === SensorType.ELECTRICITY &&
                                  s.isMain
                          )
                        : [],
                selectedSubElectricitySensors: state.sensors.filter(
                    s =>
                        s.roomRef === action.spaceId &&
                        (state.selectedSensorType === SensorType.ELECTRICITY
                            ? s.subType === SensorSubType.CONSUMPTION && s.type === state.selectedSensorType
                            : s.type === state.selectedSensorType) &&
                        !s.isMain
                ),
                selectedGasSensors:
                    state.selectedSensorType === SensorType.ELECTRICITY
                        ? state.sensors.filter(
                              s =>
                                  s.roomRef === action.spaceId &&
                                  s.subType === SensorSubType.CONSUMPTION &&
                                  s.type === SensorType.GAS
                          )
                        : [],
                selectedProductionSensors: [],
                selectedFeedinSensors: [],
                selectedOccupancySensors: state.sensors.filter(
                    s => s.roomRef === action.spaceId && s.type === SensorType.MOTION
                ),
                selectedEwattchSensors: state.ewattchMeta.filter(s => s.equipId === action.spaceId),
                selectedRoom: action.spaceId,
                breadCrumbs,
            };
        }
        case 'ADD_USAGE_DATA': {
            return {
                ...state,
                buildingStructure: action.buildingData,
            };
        }
        case 'CHANGE_DATE_SPAN': {
            const { from, to } =
                action.action === DateSpan.CUSTOM ? action.customDate : createFromToDate(action.timespan);

            const resetBuildingStructure = state.buildingStructure.map(building => {
                building.value = null;
                recursiveResetSumEnergyConsumption(building.rooms);
                return building;
            });

            return {
                ...state,
                from,
                to,
                buildingStructure: resetBuildingStructure,
            };
        }
        case 'SEARCH_SENSOR': {
            const sensorSelection = createSensorSelection(action.selectedSpace.ids);
            const selectedRoomId = getSelectedSpace(sensorSelection);
            localStorage.setItem('energy-stats-selection', JSON.stringify(sensorSelection));

            const selectedBuilding = state.buildingStructure.find(i => i.id === sensorSelection?.selectedId1);
            const selectedSecondLevelRoom = selectedBuilding?.rooms.find(i => i.id === sensorSelection?.selectedId2);
            const selectedThirdLevelRoom = selectedSecondLevelRoom?.rooms.find(
                i => i.id === sensorSelection?.selectedId3
            );
            const selectedFourthLevelRoom = selectedThirdLevelRoom?.rooms.find(
                i => i.id === sensorSelection?.selectedId4
            );
            const selectedFifthLevelRoom = selectedFourthLevelRoom?.rooms.find(
                i => i.id === sensorSelection?.selectedId5
            );

            const breadCrumbs = [
                selectedBuilding?.name,
                selectedSecondLevelRoom?.name,
                selectedThirdLevelRoom?.name,
                selectedFourthLevelRoom?.name,
                selectedFifthLevelRoom?.name,
            ].filter((i): i is string => {
                return typeof i === 'string';
            });

            return {
                ...state,
                city: selectedBuilding?.openWeather || '',
                buildingConstants: {
                    gasConstants: selectedBuilding?.gasConstants || DEFAULT_GAS_CONSTANTS,
                    electricityConstants: selectedBuilding?.electricityConstants || DEFAULT_ELECTRICITY_CONSTANTS,
                },
                equipOverview: [
                    {
                        spaces: state.equipOverview[0].spaces,
                        selectedSpace: sensorSelection?.selectedId1 ? sensorSelection.selectedId1 : '',
                    },
                    {
                        spaces: selectedBuilding?.rooms || [],
                        selectedSpace: sensorSelection?.selectedId2 ? sensorSelection.selectedId2 : '',
                    },
                    {
                        spaces: selectedSecondLevelRoom?.rooms || [],
                        selectedSpace: sensorSelection?.selectedId3 ? sensorSelection.selectedId3 : '',
                    },
                    {
                        spaces: selectedThirdLevelRoom?.rooms || [],
                        selectedSpace: sensorSelection?.selectedId4 ? sensorSelection.selectedId4 : '',
                    },
                    {
                        spaces: selectedFourthLevelRoom?.rooms || [],
                        selectedSpace: sensorSelection?.selectedId5 ? sensorSelection.selectedId5 : '',
                    },
                ],
                selectedSubElectricitySensors:
                    selectedRoomId !== ''
                        ? state.sensors.filter(
                              s =>
                                  s.roomRef === selectedRoomId &&
                                  s.subType === SensorSubType.CONSUMPTION &&
                                  s.type === state.selectedSensorType &&
                                  !s.isMain
                          )
                        : state.sensors.filter(
                              s =>
                                  s.buildingRef === selectedBuilding?.id &&
                                  s.subType === SensorSubType.CONSUMPTION &&
                                  s.type === state.selectedSensorType &&
                                  !s.isMain
                          ),
                selectedGasSensors:
                    state.selectedSensorType === SensorType.ELECTRICITY
                        ? selectedRoomId !== ''
                            ? state.sensors.filter(
                                  s =>
                                      s.roomRef === selectedRoomId &&
                                      s.subType === SensorSubType.CONSUMPTION &&
                                      s.type === SensorType.GAS
                              )
                            : state.sensors.filter(
                                  s =>
                                      s.buildingRef === selectedBuilding?.id &&
                                      s.subType === SensorSubType.CONSUMPTION &&
                                      s.type === SensorType.GAS
                              )
                        : [],
                selectedProductionSensors:
                    state.selectedSensorType === SensorType.ELECTRICITY
                        ? selectedRoomId !== ''
                            ? []
                            : state.sensors.filter(
                                  s =>
                                      s.buildingRef === selectedBuilding?.id &&
                                      s.type === state.selectedSensorType &&
                                      s.subType === SensorSubType.PRODUCTION
                              )
                        : [],
                selectedFeedinSensors:
                    state.selectedSensorType === SensorType.ELECTRICITY
                        ? selectedRoomId !== ''
                            ? []
                            : state.sensors.filter(
                                  s =>
                                      s.buildingRef === selectedBuilding?.id &&
                                      s.type === state.selectedSensorType &&
                                      s.subType === SensorSubType.FEEDIN
                              )
                        : [],
                selectedOccupancySensors:
                    selectedRoomId !== ''
                        ? state.sensors.filter(s => s.roomRef === selectedRoomId && s.type === SensorType.MOTION)
                        : state.sensors.filter(
                              s => s.buildingRef === selectedBuilding?.id && s.type === SensorType.MOTION
                          ),
                selectedEwattchSensors:
                    selectedRoomId !== ''
                        ? state.ewattchMeta.filter(s => s.equipId === selectedRoomId)
                        : state.ewattchMeta.filter(s => s.siteId === selectedBuilding?.id),
                breadCrumbs,
            };
        }
        case 'SELECT_SENSOR_TYPE': {
            localStorage.setItem(
                'energy-stats-selection',
                JSON.stringify({
                    selectedId1: '',
                })
            );
            const sensors = [
                ...state.energySensors.filter(s => s.type === action.selectedType),
                ...state.occupancySensors,
            ];

            const buildingData: Space[] = JSON.parse(JSON.stringify(state.buildingData));
            const filteredBuildingData = buildingData.filter(building =>
                Boolean(sensors.filter(f => f.buildingRef === building.id).length)
            );

            filteredBuildingData.forEach(building => {
                recursiveModuleSensorCheck(
                    building.rooms,
                    state.energySensors.filter(s =>
                        action.selectedType === SensorType.ELECTRICITY
                            ? s.type !== SensorType.HEATING && s.type !== SensorType.COOLING
                            : s.type === action.selectedType
                    )
                );
            });

            filteredBuildingData.forEach(building => {
                recursiveFilterRooms(building);
            });

            const suggestions: SpaceSuggestion[] = [];
            filteredBuildingData.forEach(building => {
                const location = [building.name];
                const ids = [building.id];
                const suggestion: SpaceSuggestion = {
                    name: building.name,
                    ids,
                    location,
                };
                suggestions.push(suggestion);
                recursiveCreateSuggestions(building.rooms, suggestions, ids, location);
            });

            return {
                ...state,
                sensors: sensors,
                selectedSensorType: action.selectedType,
                buildingStructure: filteredBuildingData,
                equipOverview: [
                    {
                        spaces: filteredBuildingData || [],
                        selectedSpace: '',
                    },
                    {
                        spaces: [],
                        selectedSpace: '',
                    },
                    {
                        spaces: [],
                        selectedSpace: '',
                    },
                    {
                        spaces: [],
                        selectedSpace: '',
                    },
                    {
                        spaces: [],
                        selectedSpace: '',
                    },
                ],
                selectedMainElectricitySensors: [],
                selectedProductionSensors: [],
                selectedFeedinSensors: [],
                selectedSubElectricitySensors: [],
                selectedGasSensors: [],
                selectedEwattchSensors: [],
                selectedOccupancySensors: [],
            };
        }
    }
};

const recursiveResetSumEnergyConsumption = (spaces: Space[]) => {
    if (spaces.length === 0) {
        return;
    }

    for (const index in spaces) {
        spaces[index].value = null;
        recursiveResetSumEnergyConsumption(spaces[index].rooms);
    }
};

const recursiveCreateSuggestions = (
    spaces: Space[],
    suggestions: SpaceSuggestion[],
    ids: string[],
    locations: string[]
) => {
    if (spaces.length === 0) {
        return;
    }
    spaces.forEach(r => {
        const i = [...ids];
        i.push(r.id);

        const l = [...locations];
        l.push(r.name);

        const suggestion: SpaceSuggestion = {
            name: r.name,
            ids: i,
            location: l,
        };
        suggestions.push(suggestion);

        recursiveCreateSuggestions(r.rooms, suggestions, i, l);
    });
};

const recursiveModuleSensorCheck = (spaces: Space[], sensors: Sensor[]) => {
    if (spaces.length === 0) {
        return;
    }

    spaces.forEach(room => {
        if (sensors.findIndex(f => f.roomRef === room.id) !== -1) {
            room.hasModuleSensor = true;
        }
        if (hasChildModuleSensorCheck(room.rooms, sensors, false)) {
            room.hasModuleSensor = true;
        }

        recursiveModuleSensorCheck(room.rooms, sensors);
    });
};

const hasChildModuleSensorCheck = (spaces: Space[], sensors: Sensor[], hasModuleSensor: boolean) => {
    let hasChildModuleSensor = hasModuleSensor;
    if (spaces.length === 0 || hasChildModuleSensor) {
        return hasChildModuleSensor;
    }
    spaces.forEach(room => {
        if (sensors.findIndex(f => f.roomRef === room.id) !== -1) {
            hasChildModuleSensor = true;
        }
        hasChildModuleSensor = hasChildModuleSensorCheck(room.rooms, sensors, hasChildModuleSensor);
    });

    return hasChildModuleSensor;
};

const recursiveFilterRooms = (space: Space) => {
    if (space.rooms.length === 0) {
        return;
    }
    space.rooms = space.rooms.filter(space => space.hasModuleSensor);

    space.rooms.forEach(s => recursiveFilterRooms(s));
};

const recursiveProcessRooms = (rooms: Space[], consumption: SpaceSumEnergyConsumption[], depth = 0) => {
    rooms.forEach(room => {
        room.value = 0; // Reset all rooms to 0
        const usage = consumption.find(c => c.roomId === room.id);
        if (usage) {
            room.value += usage.value; // Set new value
        }
        if (room.rooms.length > 0) {
            recursiveProcessRooms(room.rooms, consumption, depth + 1);
            // After processing children, sum their values up to the current room
            room.value += room.rooms.reduce((acc, child) => acc + child.value, 0);
            room.value = +room.value.toFixed(2);
            room.rooms.sort((a, b) => b.value - a.value);
        }
    });
    rooms.sort((a, b) => b.value - a.value);
};

const buildSensorLookup = (rooms: Space[], sensors: Sensor[]) => {
    const sensorLookup: SensorLookup = {};

    // Function to recursively process each room and populate the sensorLookup map
    const processRoom = (room: Space) => {
        // Initialize the set for the current room
        sensorLookup[room.id] = new Set();

        // Filter sensors that are directly referenced by this room and add their IDs
        sensors.filter(sensor => sensor.roomRef === room.id).forEach(sensor => sensorLookup[room.id].add(sensor.id));

        // Process child rooms if they exist
        if (room.rooms && room.rooms.length > 0) {
            room.rooms.forEach(childRoom => {
                processRoom(childRoom); // Recursively process each child room

                // Aggregate child room sensor IDs into the current room's set, avoiding duplicates
                sensorLookup[childRoom.id].forEach(sensorId => {
                    sensorLookup[room.id].add(sensorId);
                });
            });
        }
    };

    // Process each top-level room
    rooms.forEach(room => processRoom(room));
    return sensorLookup;
};
